import * as React from "react"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import ReCAPTCHA from "react-google-recaptcha"

interface IContactState {
  email: string
  message: string
  submitted: boolean
  sending: boolean
  error: boolean
  recaptchaRef: any
}

export default class Contact extends React.Component<any, IContactState> {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      message: "",
      submitted: false,
      sending: false,
      error: false,
      recaptchaRef: React.createRef(),
    }
  }

  onSubmit = async () => {
    const { email, message, recaptchaRef } = this.state
    this.setState({ sending: true, error: false })

    try {
      const token = await recaptchaRef.current.executeAsync()

      const res = await axios({
        method: "POST",
        url: `/.netlify/functions/contact`,
        data: JSON.stringify({ email, message, token }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (res.data.status === `success`) {
        this.setState({ submitted: true })
      } else {
        this.setState({ error: true })
        recaptchaRef.current.reset()
      }
    } catch (err) {
      this.setState({ error: true })
      recaptchaRef.current.reset()
    }

    this.setState({ sending: false })
  }

  render() {
    const { error } = this.state
    return (
      <Layout>
        <SEO title="Contact" />
        <Header />
        <ReCAPTCHA
          sitekey={`6LeyYP0ZAAAAAKBnus3UbTEbnPodiSkX3H9l2o9T`}
          size="invisible"
          ref={this.state.recaptchaRef}
        />
        <main className="content contact">
          <section className="content__row content__row--bleed static-header__header">
            <h1 className="static-header__title content__row">Laissez-nous un message</h1>
            {error ? (
              <p className="error">
                Une erreur s'est produite, votre message n'a pas été envoyé. <br />
                Merci de réessayer un peu plus tard.
              </p>
            ) : null}
            <div className="content__row static-header__content">
              {this.state.submitted ? this.renderSubmitted() : this.renderForm()}
            </div>
          </section>
        </main>
      </Layout>
    )
  }

  renderSubmitted() {
    return <h5>Merci, nous vous répondrons aussi vite que possible.</h5>
  }

  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  renderForm() {
    const { email, message, sending } = this.state
    const submitDisabled = !email || !message || !this.validateEmail(email)

    return (
      <>
        <div className="row">
          <div className="col-xl-12">
            <div className="contact-wrapper mb-30">
              <form id="contact-us-form">
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <input
                      name="email"
                      placeholder="Email"
                      type="email"
                      onChange={event => {
                        this.setState({ email: event.currentTarget.value })
                      }}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <textarea
                      name="message"
                      cols={30}
                      rows={10}
                      placeholder="Votre message"
                      onChange={event => {
                        this.setState({ message: event.currentTarget.value })
                      }}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <div className="contact-button text-center">
                      {sending ? (
                        <button className="btn" disabled>
                          En cours d'envoi...
                        </button>
                      ) : (
                        <button className="btn" onClick={this.onSubmit} disabled={submitDisabled}>
                          Envoyer
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}
